<template>
  <div class="row">
    <div class="col-6 mb-2">
      <div class="text-dark-50 font-size-sm mb-2">Adı Soyadı</div>
      <div class="font-size-lg font-weight-bold">
        {{ selectedItem.Adi }} {{ selectedItem.Soyadi }}
      </div>
    </div>
    <div class="col-6 mb-2">
      <div class="text-dark-50 font-size-sm mb-2">Email</div>
      <div class="font-size-lg font-weight-bold">
        {{ selectedItem.Eposta }}
      </div>
    </div>
    <div class="col-6 mb-2">
      <div class="text-dark-50 font-size-sm mb-2">Telefon</div>
      <div class="font-size-lg font-weight-bold">
        {{ selectedItem.Telefon ?? "-" }}
      </div>
    </div>
    <div class="col-6 mb-2">
      <div class="text-dark-50 font-size-sm mb-2">Bağlı Danışman</div>
      <div class="font-size-lg font-weight-bold">
        {{ selectedItem.BagliDanismanAdi ?? "-" }}
      </div>
    </div>
    <div class="col-6 mb-2">
      <div class="text-dark-50 font-size-sm mb-2">Eklenme Tarihi</div>
      <div class="font-size-lg font-weight-bold">
        {{ formatDate(selectedItem.OlusturmaZamani) ?? "-" }}
      </div>
    </div>
    <div class="col-6 mb-2">
      <div class="text-dark-50 font-size-sm mb-2">Durum</div>
      <div class="font-size-lg font-weight-bold">
        <div
          v-html="$customFunctions.getIsActiveBadge(selectedItem.IsActive)"
        ></div>
      </div>
    </div>
    <div class="col-6 mb-2">
      <div class="text-dark-50 font-size-sm mb-2">Silinme Durumu</div>
      <div class="font-size-lg font-weight-bold">
        <div
          v-html="$customFunctions.getIsActiveBadge(selectedItem.IsDeleted)"
        ></div>
      </div>
    </div>
    <div class="col-6 mb-2" v-if="selectedItem.IsDeleted">
      <div class="text-dark-50 font-size-sm mb-2">Silinme Zamanı</div>
      <div class="font-size-lg font-weight-bold">
        <div v-html="formatDate(selectedItem.SilinmeZamani)"></div>
      </div>
    </div>
    <div class="col-6 mb-2">
      <div class="text-dark-50 font-size-sm mb-2">E-Posta Onayı</div>
      <div class="font-size-lg font-weight-bold">
        <div
          v-html="$customFunctions.getIsActiveBadge(selectedItem.epostaOnay)"
        ></div>
      </div>
    </div>
    <div class="col-6 mb-2">
      <div class="text-dark-50 font-size-sm mb-2">Cep Telefonu Onayı</div>
      <div class="font-size-lg font-weight-bold">
        <div
          v-html="$customFunctions.getIsActiveBadge(selectedItem.cepOnay)"
        ></div>
      </div>
    </div>
    <div class="col-6 mb-2">
      <div class="text-dark-50 font-size-sm mb-2">İl</div>
      <div class="font-size-lg font-weight-bold">
        <div>
          {{ il }}
        </div>
      </div>
    </div>
    <div class="col-6 mb-2">
      <div class="text-dark-50 font-size-sm mb-2">İlçe</div>
      <div class="font-size-lg font-weight-bold">
        <div>
          {{ ilce ?? '-' }}
        </div>
      </div>
    </div>
    <div class="col-12 mb-2">
      <div class="text-dark-50 font-size-sm mb-2">Açık Adres</div>
      <div class="font-size-lg font-weight-bold">
        <div>
          {{ selectedItem.AcikAdres }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { COUNTY, DISTRICT } from "@/core/services/store/location.module";
moment.locale('tr')

export default {
  name: "DetailView",
  props: {
    selectedItem: String | Object,
  },
  data(){
    return{
      il:"",
      ilce:""
    }
  },
  mounted(){
    this.getCountyName(this.selectedItem.IlId)
    this.getDistrictName(this.selectedItem.IlceId)
  },
  methods: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    async getCountyName(il_id) {
      try {
        const response = await this.$store.dispatch(COUNTY, il_id);
        this.il = response[0].il_adi
        return response[0].il_adi;
      } catch (error) {
        console.error(error);
        return "İl Bulunamadı";
      }
    },
    async getDistrictName(ilce_id) {
      try {
        const response = await this.$store.dispatch(DISTRICT, ilce_id);
        if (response.length > 0) {
          this.ilce=response[0].ilce_adi
          return response[0].ilce_adi;
        } else {
          return "İlçe Bulunamadı";
        }
      } catch (error) {
        console.error(error);
        return "İlce Bulunamadı";
      }
    },
  },
};
</script>
